import { defineMessages } from 'react-intl';

export const scope = 'resources';

export default defineMessages({
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  pdf: {
    id: `${scope}.pdf`,
    defaultMessage: 'PDF Documents'
  },
  podcasts: {
    id: `${scope}.podcasts`,
    defaultMessage: 'Podcasts'
  },
  videos: {
    id: `${scope}.videos`,
    defaultMessage: 'Videos'
  },
  images: {
    id: `${scope}.images`,
    defaultMessage: 'Images'
  },
  loadMore: {
    id: `${scope}.loadMore`,
    defaultMessage: 'Load More'
  }
});
