import React, { useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { useIntl } from 'gatsby-plugin-intl';

import { Layout, ResourcesList, SEO, Image } from '../components';
import messages from '../messages/resources';
import searchIcon from '../assets/images/search-icon.png';
import filtersIcon from '../assets/images/slider.png';
import selectArrow from '../assets/images/dropdown-arrow.png';

const filters = [
  { label: 'All', value: '' },
  { label: 'Documents / pdf', value: 'docpdf' },
  { label: 'Podcasts', value: 'podcasts' },
  { label: 'Videos', value: 'videos' },
  { label: 'Images', value: 'images' }
];

const Resources = ({
  data: {
    allResources: { edges: allResources }
  }
}) => {
  const intl = useIntl();

  const [resources, setResources] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [filter, setFilter] = useState('');
  const itemsPerPage = 6;
  const paginate = useCallback(
    (array = []) => array.slice(0, currentPage * itemsPerPage + 1),
    [currentPage]
  );

  const search = useCallback(
    (selectedFilter = filter, input = searchInput) => {
      const filteredResources = !selectedFilter
        ? allResources
        : allResources.filter(
            ({ node: resource }) =>
              resource.postFields.resourcetype &&
              resource.postFields.resourcetype === selectedFilter
          );
      const searchResult = filteredResources.filter(({ node: resource }) =>
        resource.title.toLowerCase().includes(input.toLowerCase())
      );

      setNumberOfPages(Math.ceil(searchResult.length / itemsPerPage));
      setResources(paginate(searchResult));
    },
    [searchInput, filter, currentPage]
  );

  useEffect(() => {
    search();
  }, [currentPage]);

  return (
    <Layout>
      <SEO title={intl.formatMessage(messages.resources)} />
      <Row>
        <Col>
          <div className="activities-tabs-and-search my-5">
            <Nav tabs className="activities-tabs">
              <NavItem>
                <NavLink active className="community-nav-link">
                  {intl.formatMessage(messages.resources)}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="resources-ipsum">
            Lorem ipsum where is brian simple dummy kitchen in the house
            printing and typesetting industry. Lorem Ipsum has
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-between flex-wrap mt-5">
            <InputGroup className="activities-search-box mb-5">
              <InputGroupAddon addonType="prepend" className="search-icon">
                <Image src={filtersIcon} alt="Hivos logo" width={18} />
              </InputGroupAddon>
              <Input
                type="select"
                onChange={e => {
                  setCurrentPage(1);
                  setFilter(e.target.value);
                  search(e.target.value, searchInput);
                }}
                placeholder="Filter"
                className="search-field filters-filed"
                style={{ backgroundImage: `url(${selectArrow})` }}
              >
                {filters.map(option => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
            </InputGroup>
            <InputGroup className="activities-search-box mb-5">
              <InputGroupAddon addonType="prepend" className="search-icon">
                <Image src={searchIcon} alt="Hivos logo" width={18} />
              </InputGroupAddon>
              <Input
                type="text"
                value={searchInput}
                onChange={e => {
                  setCurrentPage(1);
                  setSearchInput(e.target.value);
                  search(filter, e.target.value);
                }}
                placeholder="Search..."
                className="search-field"
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <ResourcesList resources={resources} />
      <>
        {!(numberOfPages <= currentPage) && (
          <div className="d-flex justify-content-center">
            <Button
              disabled={numberOfPages <= currentPage}
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              {intl.formatMessage(messages.loadMore)}
            </Button>
          </div>
        )}
      </>
    </Layout>
  );
};
export default Resources;

export const query = graphql`
  {
    allResources: allWpResource {
      edges {
        node {
          id
          slug
          title
          postFields {
            file {
              sourceUrl
              mimeType
              localFile {
                publicURL
              }
            }
            resourcetype
            resourceDescription
          }
          date(formatString: "MMM DD, YYYY")
          dateEN: date(formatString: "MMM DD, YYYY")
          dateFR: date(formatString: "DD MMM YYYY", locale: "fr")
          dateAgoFr: date(fromNow: true, locale: "fr")
          dateAgoEn: date(fromNow: true, locale: "en")
          day: date(formatString: "DD")
          month: date(formatString: "MM")
        }
      }
    }
  }
`;
